import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Layout from '../components/layout/layout';
import ExportModal from '../components/exportModal/exportModal';
import DeleteSegmentModal from '../components/deleteSegmentModal/deleteSegmentModal';
import SegmentNameModal from '../components/segmentNameModal/segmentNameModal';
import Header from '../components/header/header';
import SegmentsTable from '../components/segmentsTable/segmentsTable';
import SearchBar from '../components/searchBar/searchBar';
import HomepageChart from '../components/homepageChart/homepageChart';
import ExportHistoryTable from '../components/exportHistoryTable/exportHistoryTable';
import HomepageKpi from '../components/homepageKpi/homepageKpi';
import LoadingChart from '../components/loadingChart/loadingChart';
import LoadingError from '../components/loadingError/loadingError';
import FullPageError from '../components/fullPageError/fullPageError';
import ReportsTable from '../components/reportsTable/reportsTable';
import { useGlobalState } from '../context/globalState';
import { useExportState } from '../context/exportState';
import { useBasicFiltersState } from '../context/basicFiltersState';
import { useUiState } from '../context/uiState';
import { useAuth0 } from '@auth0/auth0-react';
import { useCubeContext } from '../context/cubeState';
import { Segment as SegmentType, TableMetrics, TableColumn, KpiItem, SegmentsAndCount } from '../types/Common.interfaces';
import axios, { AxiosResponse } from 'axios';
import { differenceInCalendarMonths, differenceInDays, format, subDays, subMonths } from 'date-fns';
import { validateSegmentName } from '../utils/validateSegmentName';
import { Box, Typography, Card, Skeleton } from '@mui/material';
import { GridColDef, GridSortModel, useGridApiRef } from '@mui/x-data-grid-pro';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { Sort, AudienceFilter, CombinedAudienceFilter } from '../types/Cube.interfaces';
import { ResultSet } from '@cubejs-client/core';
import { generateNameAndId } from '../utils/nameAndIdGenerator';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import useCubejsApiWrapper from '../apiHelpers/cubejsWrapper';

const Home: NextPage = () => {
  const { user } = useAuth0();
  const {
    apiHeaders, organization, activeDataset, totalPersons, organizationData, loadingCompanyMetrics, loadingPeopleMetrics, metricsError,
    peopleColumns, companyColumns, loadingOrganizationData, error, currentTab, setCurrentTab, userMetadata, companyMetricsData, peopleMetricsData,
    captureEventInPostHog, companyDatabaseColumns, peopleDatabaseColumns, velocityProjectName, monthsValue, setMonthsValue, loadingTotalData,
    totalCompanies
  } = useGlobalState();
  const { handleDownloadData } = useExportState();
  const { setAudienceFilters, modifyJobTitleFilter } = useBasicFiltersState();
  const {
    displayToast, setOpenTamDrawer, setOpenPersonaDefinitionDrawer, setOpenCustomInsightsDrawer, setOpenPeopleDrawer
  } = useUiState();
  const { cubejsApi } = useCubeContext();
  const [segments, setSegments] = useState<SegmentType[]>([]);
  const [loadingAudiences, setLoadingAudiences] = useState<boolean>(false);
  const [controller] = useState<AbortController>(new AbortController());
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);
  const [exportError, setExportError] = useState<boolean>(false);
  const [segmentToExport, setSegmentToExport] = useState<SegmentType | null>(null);
  const [loadingSegmentsError, setLoadingSegmentsError] = useState<boolean>(false);
  const [segmentToDelete, setSegmentToDelete] = useState<SegmentType | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [segmentToClone, setSegmentToClone] = useState<SegmentType | null>(null);
  const [openCloneModal, setOpenCloneModal] = useState<boolean>(false);
  const [savingSegmentError, setSavingSegmentError] = useState<boolean>(false);
  const [savingSegment, setSavingSegment] = useState<boolean>(false);
  const [segmentNameError, setSegmentNameError] = useState<boolean>(false);
  const [segmentName, setSegmentName] = useState<string>('');
  const [sortData, setSortData] = useState<Sort>({ column: 'createdAt', value: 'desc' });
  const [selectedCompanyMetric, setSelectedCompanyMetric] = useState<TableMetrics | null>(null);
  const [selectedPeopleMetric, setSelectedPeopleMetric] = useState<TableMetrics | null>(null);
  const [page] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const [personaContactsNumber, setPersonaContactsNumber] = useState<number>(0);
  const [loadingPersonaContactsNumber, setLoadingPersonaContactsNumber] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [personaOptions, setPersonaOptions] = useState<string[]>([]);
  const [exportingReport, setExportingReport] = useState<boolean>(false);
  const [loadingSegmentsCount, setLoadingSegmentsCount] = useState<boolean>(false);
  const [phoneNumbersCount, setPhoneNumbersCount] = useState<number>(0);
  const [emailsCount, setEmailsCount] = useState<number>(0);
  const [loadingKpis, setLoadingKpis] = useState<boolean>(false);
  const [segmentToExportOrgCount, setSegmentToExportOrgCount] = useState<number>(0);
  const [segmentToExportPeopleCount, setSegmentToExportPeopleCount] = useState<number>(0);
  const segmentsTableRef = useGridApiRef();
  const router = useRouter();
  const cubejsApiWrapper = useCubejsApiWrapper();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, [controller]);

  useEffect(() => {
    if (organization) {
      setSegments([]);
      setSelectedCompanyMetric(null);
      setSelectedPeopleMetric(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  useEffect(() => {
    if (apiHeaders && organizationData && activeDataset) {
      loadSegments(sortData.column, sortData.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiHeaders, organizationData, activeDataset, selectedOptions]);

  useEffect(() => {
    if (cubejsApi) {
      (async function iife () {
        try {
          setLoadingPersonaContactsNumber(true);
          const resultSet: ResultSet<any> = await cubejsApiWrapper({
            measures: ['TAM.countDistinct'],
            dimensions: ['TAM.persona_per'],
            segments: ['TAM.mapped_persona']
          });
          const optionsForPersona: string[] = [];
          let totalNumber = 0;
          resultSet.tablePivot().forEach((value: { [key: string]: string | number | boolean }) => {
            totalNumber += +value['TAM.countDistinct'];
            optionsForPersona.push(value['TAM.persona_per'] as string);
          });
          setPersonaOptions(optionsForPersona);
          setPersonaContactsNumber(totalNumber);
        } catch (error) {
          setPersonaContactsNumber(0);
          setPersonaOptions([]);
        } finally {
          setLoadingPersonaContactsNumber(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cubejsApi]);

  useEffect(() => {
    if (cubejsApi) {
      (async function iife () {
        try {
          setLoadingKpis(true);
          const emailResultSet: ResultSet<any> = await cubejsApiWrapper({
            measures: ['TAM.countDistinct'],
            segments: ['TAM.valid_emails']
          });
          const phoneResultSet: ResultSet<any> = await cubejsApiWrapper({
            measures: ['TAM.countDistinct'],
            segments: ['TAM.phone_numbers']
          });
          setEmailsCount(+emailResultSet.tablePivot()[0]['TAM.countDistinct']);
          setPhoneNumbersCount(+phoneResultSet.tablePivot()[0]['TAM.countDistinct']);
        } catch (error) {
          setEmailsCount(0);
          setPhoneNumbersCount(0);
        } finally {
          setLoadingKpis(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cubejsApi]);

  const loadSegments = async (orderBy: string, direction: string) => {
    if (apiHeaders && organizationData && activeDataset) {
      try {
        setSegments([]);
        setLoadingAudiences(true);
        setLoadingSegmentsError(false);
        const filterParams = selectedOptions.length > 0 ? `&filters=${selectedOptions.join(',')}` : '';
        const queryParams = `?count=5&page=1&${filterParams}&orderBy=${orderBy}&direction=${direction}`;
        const response: AxiosResponse<SegmentsAndCount> = await axios.get(
          `/api/clients/${organizationData.id}/datasets/${activeDataset.id}/segments${queryParams}`,
          { ...apiHeaders, signal: controller.signal }
        );
        setSegments(response.data.segments);
        updateSegmentsCount(response.data.segments);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setSegments([]);
          setLoadingSegmentsError(true);
        }
      } finally {
        setLoadingAudiences(false);
      }
    }
  };

  const updateSegmentsCount = async (segmentsArray: SegmentType[]) => {
    try {
      setLoadingSegmentsCount(true);
      const promises = segmentsArray.map(async (segment: SegmentType) => {
        const modifiedFilters = handleModifyDateFilters(segment, segment.filters);
        const resultCompanySet: ResultSet<any> = await cubejsApiWrapper({
          measures: ['COMPANY.countDistinct'],
          filters: modifyJobTitleFilter(modifiedFilters),
        });
        const resultPeopleSet: ResultSet<any> = await cubejsApiWrapper({
          measures: ['TAM.countDistinct'],
          filters: modifyJobTitleFilter(modifiedFilters),
        });
        const companyCount = +resultCompanySet.tablePivot()[0]['COMPANY.countDistinct'];
        const peopleCount = +resultPeopleSet.tablePivot()[0]['TAM.countDistinct'];
        return { ...segment, companyCount, peopleCount };
      });
      const audiences = await Promise.all(promises);
      setSegments(audiences);
    } catch (error) {
      if (!axios.isCancel(error)) {}
    } finally {
      setLoadingSegmentsCount(false);
    }
  };

  const handleExportData = (table: string, columns:{ [key: string]: boolean }) => {
    setExportError(false);
    if (segmentToExport) {
      const peopleStat = segmentToExport.peopleCount;
      const companyStat = segmentToExport.companyCount;
      const { name, id } = generateNameAndId(false, segmentToExport.displayName);
      if (table === 'company') {
        if (companyStat) {
          const visibleColumns = companyColumns.filter((column: GridColDef) => columns[column.field]);
          handleDownloadData(
            setOpenExportModal, name, visibleColumns, segmentToExport.filters, table, setExportError, id
          );
        }
      } else if (table === 'people') {
        if (peopleStat) {
          const visibleColumns = [...peopleColumns, ...companyColumns].filter((column: GridColDef) => columns[column.field]);
          handleDownloadData(
            setOpenExportModal, name, visibleColumns, segmentToExport.filters, table, setExportError, id
          );
        }
      }
      setSegmentToExport(null);
    } else {

    }
  };

  const handleTabChange = (value: string) => {
    setCurrentTab(value);
  };

  const handleDeleteSegment = async () => {
    if (organizationData && activeDataset && segmentToDelete && apiHeaders && user) {
      try {
        setOpenDeleteModal(false);
        const body = {
          data: {
            isDeleted: true,
          }
        };
        // eslint-disable-next-line no-unused-vars
        const response: AxiosResponse<SegmentType> = await axios.patch(
          `/api/clients/${organizationData.id}/datasets/${activeDataset.id}/segments/${segmentToDelete.id}`,
          JSON.stringify(body),
          { ...apiHeaders, signal: controller.signal }
        );
        displayToast('Your segment has been deleted!', 'success');
        captureEventInPostHog('Delete segment', activeDataset.productName);
        loadSegments(sortData.column, sortData.value);
      } catch (error) {
        if (!axios.isCancel(error)) {

        }
      }
    }
  };

  const handleCloneSegment = async () => {
    if (organizationData && activeDataset && segmentToClone && apiHeaders && user) {
      const isSegmentNameValid = validateSegmentName(segmentName);
      if (!isSegmentNameValid) {
        setSegmentNameError(true);
      } else {
        try {
          setSegmentNameError(false);
          setSavingSegment(true);
          const body = {
            name: segmentName,
            displayName: segmentName,
            createdBy: user.sub,
            filters: segmentToClone.filters,
            user: {
              userId: user.sub,
              firstName: user[userMetadata].first_name || '',
              lastName: user[userMetadata].last_name || '',
              email: user.email || '',
              orgId: organizationData.id,
            },
            segmentColumns: [...segmentToClone.segmentColumns],
          };
          // eslint-disable-next-line no-unused-vars
          const response: AxiosResponse<SegmentType> = await axios.post(
            `/api/clients/${organizationData.id}/datasets/${activeDataset.id}/segments`,
            JSON.stringify(body),
            { ...apiHeaders, signal: controller.signal }
          );
          displayToast('Your segment has been cloned!', 'success');
          captureEventInPostHog('Save segment', activeDataset.productName);
          setOpenCloneModal(false);
          setSegmentName('');
          loadSegments(sortData.column, sortData.value);
        } catch (error) {
          if (!axios.isCancel(error)) {
            if (error.response.status === 400) {
              setSegmentNameError(true);
              captureEventInPostHog('Save segment failed', activeDataset.productName);
            } else {
              setSavingSegmentError(true);
            }
          }
        } finally {
          setSavingSegment(false);
        }
      }
    }
  };

  const calculateCustomInsightsNumber = (): number => {
    return [...peopleDatabaseColumns, ...companyDatabaseColumns].filter((column: TableColumn) => column.isCustom).length;
  };

  const handleModifyDateFilters = (
    segment: SegmentType,
    filters: (AudienceFilter | CombinedAudienceFilter)[]
  ): (AudienceFilter | CombinedAudienceFilter)[] => {
    const now = format(new Date(Date.now()), 'yyyy-MM-dd');
    return filters.map((filter: AudienceFilter | CombinedAudienceFilter) => {
      if (filter.hasOwnProperty('operator') && filter['operator'] === 'afterDate' && filter['values']) {
        let dateValue;
        const daysDifference = segment ? differenceInDays(new Date(segment.updatedAt), new Date(filter['values'][0])) : 0;
        if (daysDifference > 30) {
          const monthDifference = segment ? differenceInCalendarMonths(new Date(segment.updatedAt), new Date(filter['values'][0])) : 0;
          dateValue = format(subMonths(new Date(now), monthDifference), 'yyyy-MM-dd');
        } else {
          dateValue = format(subDays(new Date(now), daysDifference), 'yyyy-MM-dd');
        }
        return {
          ...filter,
          values: [dateValue],
        };
      } else {
        return filter;
      }
    });
  };

  const handleSortChange = async (model: GridSortModel) => {
    if (model.length && model[0].sort) {
      setSortData({ column: model[0].field, value: model[0].sort });
      loadSegments(model[0].field, model[0].sort);
    } else {
      setSortData({ column: 'createdAt', value: 'desc' });
      loadSegments('createdAt', 'desc');
    }
  };

  const handleExportSegment = (segment: SegmentType) => {
    setExportingReport(false);
    setOpenExportModal(true);
    setSegmentToExport(segment);
    setSegmentToExportOrgCount(segment.companyCount);
    setSegmentToExportPeopleCount(segment.peopleCount);
  };

  const deleteSegment = (segment: SegmentType) => {
    setOpenDeleteModal(true);
    setSegmentToDelete(segment);
  };

  const cloneSegment = (segment: SegmentType) => {
    setOpenCloneModal(true);
    setSegmentToClone(segment);
  };

  const handleCloseModal = () => {
    setOpenExportModal(false);
    setSegmentToExport(null);
  };

  const kpiItems: KpiItem[] = [
    {
      setOpenDrawer: setOpenTamDrawer,
      kpiNumber: totalCompanies,
      loading: loadingOrganizationData || loadingTotalData,
      headerText: 'Organizations',
      iconElement: (
        <Box className='u-mr-20'>
          <Box className='kpi-insights-background u-w-64-px u-h-64-px u-flex u-flex-justify-center u-flex u-flex-align-center'>
            <ApartmentRoundedIcon sx={{ fontSize: '46px', color: '#fff' }} />
          </Box>
        </Box>
      ),
      handleClick: () => {
        setAudienceFilters([]);
        router.push('/database?tab=organizations');
      },
      tooltipText: 'Organization Definitions'
    },
    {
      setOpenDrawer: setOpenPeopleDrawer,
      kpiNumber: totalPersons,
      loading: loadingOrganizationData || loadingCompanyMetrics || loadingPeopleMetrics,
      headerText: 'People',
      iconElement: (
        <Box className='u-mr-20'>
          <Box className='kpi-insights-background u-w-64-px u-h-64-px u-flex u-flex-justify-center u-flex u-flex-align-center'>
            <GroupsRoundedIcon sx={{ fontSize: '46px', color: '#fff' }} />
          </Box>
        </Box>
      ),
      handleClick: () => {
        setAudienceFilters([]);
        router.push('/database?tab=people');
      },
      tooltipText: 'People Definitions',
      kpiNumbers: {
        phoneNumber: phoneNumbersCount,
        emailNumber: emailsCount,
        loadingKpis: loadingKpis || loadingOrganizationData,
      }
    },
    {
      setOpenDrawer: setOpenPersonaDefinitionDrawer,
      kpiNumber: personaContactsNumber,
      loading: loadingOrganizationData || loadingPersonaContactsNumber,
      headerText: 'Personas',
      iconElement: (
        <Box className='u-mr-20'>
          <Box className='u-w-64-px u-h-64-px u-flex u-flex-justify-center u-flex u-flex-align-center'>
            <AccountCircleIcon sx={{ fontSize: '64px' }} color='info' />
          </Box>
        </Box>
      ),
      handleClick: () => {
        setAudienceFilters([
          {
            member: 'TAM.persona_per',
            operator: velocityProjectName ? 'contains' : 'equals',
            values: [...personaOptions]
          }
        ]);
        router.push('/database?tab=people');
      },
      tooltipText: 'Persona Contacts Definitions'
    },
    {
      setOpenDrawer: setOpenCustomInsightsDrawer,
      kpiNumber: calculateCustomInsightsNumber(),
      loading: loadingOrganizationData || loadingCompanyMetrics || loadingPeopleMetrics,
      headerText: 'Buyer Insights',
      iconElement: (
        <Box className='u-mr-20'>
          <Box className='kpi-insights-background u-w-64-px u-h-64-px u-flex u-flex-justify-center u-flex u-flex-align-center'>
            <AutoAwesomeOutlinedIcon sx={{ fontSize: '32px', color: '#fff' }} />
          </Box>
        </Box>
      ),
      tooltipText: 'Buyer Insights Definitions'
    },
  ];

  const handleFilterChange = (value: string[]) => {
    setSelectedOptions(value);
  };

  const handleRefreshSegments = () => {
    loadSegments(sortData.column, sortData.value);
  };

  return (
    <Layout title='Home'>
      <Header
        title='Database Overview'
        velocityProjectName={velocityProjectName}
        orgName={!error && !loadingOrganizationData && !velocityProjectName ? organization?.display_name : ''}
      />
      {error ? (
        <FullPageError databaseError={false} includeSearchHeight={false} />
      ) : (
        <>
          <SearchBar setAudienceFilters={setAudienceFilters} shouldRedirect />
          <Box className='u-py-20px u-px-40'>
            {!loadingOrganizationData ? (
              metricsError ? (
                <LoadingError databaseError classes='u-py-32 u-mt-32' />
              ) : (
                <Box
                  className='u-flex u-flex-justify-start u-flex-align-stretch u-flex-wrap u-mt-32 u-w-100 kpi-wrapper'
                  sx={{ columnGap: '16px', rowGap: '16px' }}
                >
                  <Box
                    className='u-flex u-flex-justify-start u-flex-align-stretch u-flex-wrap u-flex-50'
                    sx={{ columnGap: '16px', rowGap: '16px' }}
                  >
                    {kpiItems.slice(0,2).map((item: KpiItem, index: number) => (
                      <HomepageKpi key={index} {...item} />
                    ))}
                  </Box>
                  <Box
                    className='u-flex u-flex-justify-start u-flex-align-stretch u-flex-wrap u-flex-40'
                    sx={{ columnGap: '16px', rowGap: '16px' }}
                  >
                    {kpiItems.slice(2).map((item: KpiItem, index: number) => (
                      <HomepageKpi key={index} {...item} />
                    ))}
                  </Box>
                </Box>
              )
            ) : (
              <Box
                className='u-flex u-flex-justify-start u-flex-align-stretch u-flex-wrap u-mt-32 u-w-100 kpi-wrapper'
                sx={{ columnGap: '16px', rowGap: '16px' }}
              >
                <Box
                  className='u-flex u-flex-justify-start u-flex-align-stretch u-flex-wrap u-flex-40'
                  sx={{ columnGap: '16px', rowGap: '16px' }}
                >
                  {Array.from(Array(2).keys()).map((value: number) => (
                    <Card
                      key={value + 1}
                      className='u-flex u-flex-justify-start u-flex-align-stretch u-flex-wrap u-flex-25 u-br-8-px'
                    >
                      <Box className='u-flex u-p-16 u-flex-100'>
                        <Box className='u-mr-20'>
                          <Skeleton variant='circular' width={60} height={60} sx={{ bgcolor: '#F5EEFA' }} />
                        </Box>
                        <Box className='u-flex u-flex u-flex-direction-column u-flex-space-between u-pr-24 u-flex-100'>
                          <Box>
                            <Skeleton variant='rectangular' height={35} className='u-mb-12' sx={{ bgcolor: '#F5EEFA' }} />
                            <Skeleton variant='rectangular' height={35} className='u-mb-12' sx={{ bgcolor: '#F5EEFA' }} />
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  ))}
                </Box>
                <Box
                  className='u-flex u-flex-justify-start u-flex-align-stretch u-flex-wrap u-flex-40'
                  sx={{ columnGap: '16px', rowGap: '16px' }}
                >
                  {Array.from(Array(2).keys()).map((value: number) => (
                    <Card
                      key={value + 1}
                      className='u-flex u-flex-justify-start u-flex-align-stretch u-flex-wrap u-flex-25 u-br-8-px'
                    >
                      <Box className='u-flex u-p-16 u-flex-100'>
                        <Box className='u-mr-20'>
                          <Skeleton variant='circular' width={60} height={60} sx={{ bgcolor: '#F5EEFA' }} />
                        </Box>
                        <Box className='u-flex u-flex u-flex-direction-column u-flex-space-between u-pr-24 u-flex-100'>
                          <Box>
                            <Skeleton variant='rectangular' height={35} className='u-mb-12' sx={{ bgcolor: '#F5EEFA' }} />
                            <Skeleton variant='rectangular' height={35} className='u-mb-12' sx={{ bgcolor: '#F5EEFA' }} />
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  ))}
                </Box>
              </Box>
            )}
            {loadingOrganizationData || loadingCompanyMetrics || loadingPeopleMetrics ? (
              <LoadingChart />
            ) : (
              metricsError ? (
                <LoadingError databaseError={false} classes='u-py-128 u-mt-32' />
              ) : (
                <>
                  <HomepageChart
                    currentTab={currentTab}
                    tabValue='organizations'
                    handleTabChange={handleTabChange}
                    metricsData={companyMetricsData}
                    selectedMetrics={selectedCompanyMetric}
                    monthsValue={monthsValue}
                    setMonthsValue={setMonthsValue}
                    setSelectedMetrics={setSelectedCompanyMetric}
                    table='company'
                  />
                  <HomepageChart
                    currentTab={currentTab}
                    tabValue='people'
                    handleTabChange={handleTabChange}
                    metricsData={peopleMetricsData}
                    selectedMetrics={selectedPeopleMetric}
                    monthsValue={monthsValue}
                    setMonthsValue={setMonthsValue}
                    setSelectedMetrics={setSelectedPeopleMetric}
                    table='tam'
                  />
                </>
              )
            )}
            <Box>
              <Typography className='u-py-48 fs-title-large'>Recent Activity</Typography>
              {loadingSegmentsError ? (
                <LoadingError databaseError classes='u-py-128 u-mb-40' />
              ) : (
                <Box className='u-mb-40'>
                  <SegmentsTable
                    rows={segments}
                    page={page}
                    pageSize={pageSize}
                    loading={loadingAudiences || loadingOrganizationData}
                    rowCount={10}
                    autoHeight={true}
                    apiRef={segmentsTableRef}
                    handleSortChange={handleSortChange}
                    handlePageChanged={() => {}}
                    handleChangePageSize={() => {}}
                    exportSegment={handleExportSegment}
                    deleteSegment={deleteSegment}
                    cloneSegment={cloneSegment}
                    handleFilterChange={handleFilterChange}
                    seeAllButton={true}
                    title='Recently Created Segments'
                    hideFooter={true}
                    selectedOptions={selectedOptions}
                    skeletonRows={5}
                    loadingSegmentsCount={loadingSegmentsCount}
                    handleRefreshSegments={handleRefreshSegments}
                  />
                </Box>
              )}
              <Box className='u-mb-40'>
                <ExportHistoryTable
                  isExportHistoryPage={false}
                  hideFooter
                  hideFooterPagination
                  pagination={false}
                  autoHeight={true}
                  skeletonRows={5}
                />
              </Box>
              <Box className='u-mb-40'>
                <ReportsTable
                  title='Recently Created Reports'
                  seeAllButton={true}
                  hideFooter={true}
                  autoHeight={true}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
      <ExportModal
        open={openExportModal}
        handleCloseModal={handleCloseModal}
        handleDownloadData={handleExportData}
        reportExport={exportingReport}
        exportOrgSize={segmentToExportOrgCount}
        exportPeopleSize={segmentToExportPeopleCount}
      />
      <DeleteSegmentModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        handleAccept={handleDeleteSegment}
        segmentName={segmentToDelete?.displayName || ''}
        setSegmentToDelete={setSegmentToDelete}
        type='segment'
      />
      <SegmentNameModal
        open={openCloneModal}
        setOpen={setOpenCloneModal}
        segmentName={segmentName}
        setSegmentName={setSegmentName}
        handleCreateSegment={handleCloneSegment}
        segmentNameError={segmentNameError}
        setSegmentNameError={setSegmentNameError}
        savingSegment={savingSegment}
        savingSegmentError={savingSegmentError}
        setSavingSegmentError={setSavingSegmentError}
      />
    </Layout>
  );
};

export default Home;